import React from 'react';

import styles from './ConnectionSection.module.scss';

function ConnectionSection({ 
  image, 
  title, 
  description, 
  infoURL,
  button,
}) {
  return (<div className={styles.connectionSection}>
    <div className={styles.imageContainer}>
      {image}
    </div>
    <div className={styles.textContainer}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
    <div className={styles.rightContainer}>
      <a href={infoURL} target="_blank" rel="noopener noreferrer">
        Learn more
      </a>
      <div className={styles.buttonContainer}>{button}</div>
    </div>
  </div>);
}

export default ConnectionSection;
