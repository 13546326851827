import React, { useState } from 'react';

import { faBan, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Blacklist from './Blacklist';
import styles from './Customer.module.scss';
import GDPR from './GDPR';

function TabLayout({ tabs }) {
  const [currTab, setCurrTab] = useState(0);
  return (<div className={styles.tabLayout}>
    <span className={styles.tabsContainer}>
      {tabs.map((tab, i) => <div 
        onClick={() => setCurrTab(i)} 
        key={i}
        className={`${styles.tab} ${currTab == i ? styles.activeTab : ''}`} >
        {tab.label}
      </div>)}
    </span>
    <div className={styles.tabContent}>
      {tabs[currTab].render()}
    </div>
  </div>);
}

function Customer() {
  return (<div className={styles.mainContainer}>
    <div className={styles.heading}><h3>Manage Customers</h3></div>
    <TabLayout tabs={[
      {label: <div>
        <FontAwesomeIcon icon={faBan} /> Blacklist
      </div>, render: () => <Blacklist />},
      {label: <div>
        <FontAwesomeIcon icon={faUserLock} /> GDPR
      </div>, render: () => <GDPR />},
    ]} />
  </div>);
}

export default Customer;
