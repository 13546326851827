import React, { useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import styles from './MainLayout.module.scss';
import { AuthContext } from '../../context/AuthContext';
import useAuth from '../../hooks/useAuth';
import { clearAuthToken } from '../../services/AuthService';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';


function MainLayout({ children }) {
  // const [userPhoto, setUserPhoto] = useState(null);
  const [isAuth, setIsAuth] = useAuth();

  // const getUserPhoto = async () => {
  //   // Simulate fetching user photo from the backend API
  //   try {
  //     const response = await fetch(defaultUserPhoto);
  //     const blob = await response.blob();
  //     setUserPhoto(URL.createObjectURL(blob));
  //   } catch (error) {
  //     console.error('Error fetching user photo:', error);
  //     setUserPhoto(defaultUserPhoto);
  //   }
  // };

  const logOut = () => {
    clearAuthToken();
    setIsAuth(false);
  };

  useEffect(() => {
    // getUserPhoto();
    window.addEventListener('auth-expired', logOut);

    return () => {
      window.removeEventListener('auth-expired', logOut);
    };
  }, []);

  if (!isAuth) {
    return (<Redirect to="/login" />);
  }

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      <div className={styles.container}>
        <Header logOut={logOut} />
        <div className={styles.innerContainer}>
          <Sidebar />
          <div className={styles.mainContent}>
            <div className={styles.contentContainer}>{children}</div>
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
}

export default MainLayout;
