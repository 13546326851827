import React from 'react';

import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@headlessui/react';

import styles from './ModalPopUp.module.scss';

const ModalPopUp = ({ isOpen, onClose, title, description, disabled, children, includeCloseX=false }) => {
  return (
    <Dialog open={isOpen} 
      className={`${styles.modalOverlay} ${disabled ? styles.disabled : ''}`}
      onClose={() => {
        if (!disabled) onClose();
      }}
    >
      <Dialog.Panel className={styles.modal}>
        {/* {includeCloseX && <FontAwesomeIcon icon={faX} onClick={onClose} className={styles.closeButton} />} */}
        <div className={styles.heading}>
          {title && <Dialog.Title className={styles.modalTitle}>{title}</Dialog.Title>}
          {includeCloseX && <FontAwesomeIcon icon={faX} onClick={onClose} className={styles.closeButton} />}
        </div>
        <div className={styles.content}>
          {description && <Dialog.Description as='div' className={styles.modalDescription}>
            {description}
          </Dialog.Description>}
          <div>{children}</div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default ModalPopUp;
