import ApiService from './ApiService';

const CustomerService = {
  getBlacklistedEmails: async (id) => {
    return await ApiService.get(`/companies/${id}/blacklist/email`);
  },
  blacklistEmail: async (id, email) => {
    return await ApiService.post(`/companies/${id}/blacklist/email/`, {email});
  },
  removeEmailFromBlacklist: async (companyId, emailId) => {
    return await ApiService.delete(`/companies/${companyId}/blacklist/email/${emailId}/`);
  },
  getBlacklistedPostcodes: async (id) => {
    return await ApiService.get(`/companies/${id}/blacklist/postcode`);
  },
  blacklistPostcode: async (id, postcode) => {
    return await ApiService.post(`/companies/${id}/blacklist/postcode`, {postcode});
  },
  removePostcodeFromBlacklist: async (companyId, postcodeId) => {
    return await ApiService.delete(`/companies/${companyId}/blacklist/postcode/${postcodeId}/`);
  },
  removeCustomerData: async (id, email) => {
    return await ApiService.delete(`/gdpr/${id}/customer/${email}/`);
  },
  getGDPRRemovalList: async (id) => {
    return await ApiService.get(`/gdpr/${id}/removal_list`);
  },
};

export default CustomerService;
