import ApiService from './ApiService';

const ApiKeyService = {
  uploadApiKey: async (body) => {
    return await ApiService.post('/api_key_upload/upload/', { body });
  },
  checkApiKey: async (service) => {
    return await ApiService.get(`/api_key_upload/check/${service}/`);
  },
  checkPaperplanesApiKey: async () => {
    return await ApiService.get(`/webhook/api-key/check`);
  },
  generatePaperplanesApiKey: async () => {
    return await ApiService.post(`/webhook/api-key/generate`);
  },
  retrievePaperplanesApiKey: async (password) => {
    return await ApiService.post(`/webhook/api-key/retrieve`, { password });
  },
};

export default ApiKeyService;
